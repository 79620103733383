// Require optional shared components
require(['componentLoader'], function(helper) {
  var config = THEHUT.config;

  // Make these modules dependencies
  helper.promote([
    'productPageSlider',
    'cCarousel'
  ]);

  helper.requireDependencies();
});

// This module's dependencies only
var deps = [
  'jquery'
];

define(deps, function($) {
  // Bundle Products Accordion
  var accordionHeadings = ('.bundle-section .widget-header');
  var accordionSiblings = $(accordionHeadings).siblings('.bundle-product-wrap');

  $(accordionSiblings).first().slideToggle().parent().addClass('selected');
  $(accordionHeadings).click(function() {
    var $this = $(this).siblings('.bundle-product-wrap');
    if ($this.is(':animated')) {
      return false;
    }
    if ($this.parent().hasClass('selected')) {
      $this
        .slideToggle()
        .parent()
        .removeClass('selected');

    } else {
      $this.parent()
        .removeClass('selected')
        .find(accordionSiblings)
        .slideUp();
      $this
        .slideToggle()
        .parent()
        .addClass('selected');
    }
    return false;
  });

  require(['youtube'], function(youtube) {
    youtube('.js-youtube-video').done(function(videosArray) {
      // ytVideosArray: array of Youtube videos and player Api controls for further customisation

      var activeYTThumbClass = 'widget-youtube__gallery__menu__items__item--active';
      $('.youtube-video__gallery__menu__item').on('click', function() {
        $('.youtube-video__gallery__menu__item').removeClass(activeYTThumbClass);
        $(this).addClass(activeYTThumbClass);
      });
    });
  });

});
